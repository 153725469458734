import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bbae2a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mt-12 text-center" }
const _hoisted_4 = { class: "mt-4 text-black-50 text-center" }
const _hoisted_5 = { class: "mt-12 d-grid" }
const _hoisted_6 = { class: "btn btn-primary" }
const _hoisted_7 = {
  key: 1,
  class: "text-center mt-4"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!
  const _component_Portal = _resolveComponent("Portal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WaitForResource, {
      resource: _ctx.invitation,
      "show-error-details": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "logo rounded-2 bg-light",
            src: _ctx.logo || require('@/assets/images/pitchy.png')
          }, null, 8, _hoisted_2)
        ]),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('invitations.title')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('invitations.body')), 1),
        (_ctx.token)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "mt-12",
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.accept && _ctx.accept(...args)), ["prevent"]))
            }, [
              _createVNode(_component_FormGroup, {
                disabled: "",
                label: _ctx.$t('auth.email'),
                "model-value": _ctx.invitation?.user?.email,
                name: "email",
                type: "email"
              }, null, 8, ["label", "model-value"]),
              _createVNode(_component_PasswordField, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                class: "mt-12",
                errors: _ctx.errors,
                label: _ctx.$t('invitations.createPassword')
              }, null, 8, ["modelValue", "errors", "label"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('invitations.actions.getStarted')), 1)
              ])
            ], 32))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('invitations.alreadyAccepted')), 1))
      ]),
      _: 1
    }, 8, ["resource"]),
    (_ctx.banner)
      ? (_openBlock(), _createBlock(_component_Portal, {
          key: 0,
          to: "auth-banner"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "vh-100",
              src: _ctx.banner
            }, null, 8, _hoisted_8)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}