
import {defineComponent, ref} from 'vue';
import Errors from '@/library/model-collection/Errors';
import FormGroup from '@/components/common/form/FormGroup.vue';
import Invitation from '@/models/Invitation';
import PasswordField from '@/components/common/form/PasswordField.vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';
import TeamType from '@/library/enumerations/TeamType';
import auth from '@/store/auth';
import axios from 'axios';
import get from 'lodash/get';
import {useRoute} from 'vue-router';

export default defineComponent({
    components: {
        FormGroup,
        PasswordField,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();

        const id = parseInt(props.id.toString(), 10);

        return {
            password: ref<string>(''),
            token: ref(route.query.token),
            invitation: ref(new Invitation({id})),
            errors: ref(new Errors()),
        };
    },
    computed: {
        banner(): string | undefined {
            if (
                this.invitation
                && this.invitation.model instanceof Team
            ) {
                return this.invitation.model.teamBanner;
            }

            return undefined;
        },
        logo(): string | undefined {
            return get(
                this.invitation,
                'model.logo.thumbMd',
            );
        },
    },
    created() {
        this.getInvitation();
    },
    methods: {
        async accept() {
            try {
                const response = await axios.post(`invitations/${this.id}/accept`, {password: this.password}, {
                    params: {
                        token: this.token,
                    },
                });

                auth.setApiToken(response.data.apiToken);
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                this.errors.setErrors(e.response.data.errors);

                return;
            }

            const route: Record<string, any> = {
                name: 'profile.create',
            };

            const {model, roles} = this.invitation;

            if (
                !(model instanceof Team)
                || (model.type === TeamType.TEAM && roles && !roles.includes(Role.ADMIN))
            ) {
                await this.$router.push(route);

                return;
            }

            route.query = {
                teamId: model.id,
                teamType: model.type.toString(),
            };

            await this.$router.push(route);
        },
        async getInvitation() {
            await this.invitation.fetch({
                params: {
                    token: this.token,
                },
            });

            if (auth.me.value && this.invitation.user?.id !== auth.me.value.id) {
                await this.$router.push({name: 'home'});
                return;
            }

            if (this.token && !this.invitation.acceptedAt) {
                return;
            }

            if (!this.invitation.acceptedAt) {
                await axios.post(`invitations/${this.id}/accept`);
            }

            this.toFinaliseTeam();
        },
        toFinaliseTeam() {
            const {model} = this.invitation;

            if (!model || !(model instanceof Team)) {
                return;
            }

            /*
             * For Team teams, the user needs to have admin rights to create the
             * team. For Organisation teams, this role check is not required.
             */
            if (
                model.type === TeamType.TEAM
                && (!this.invitation.roles || !this.invitation.roles.includes(Role.ADMIN))
            ) {
                this.$router.push({name: 'home'});

                return;
            }

            this.$router.push(model.getLocation('register'));
        },
    },
});
